<template>
  <div v-html="svg"></div>
</template>

<script setup lang="ts">
const { name, src } = withDefaults(defineProps<{
  name: string,
  src?: string,
}>(), {
  src: '',
})
const file = `${src}${name}`
const modules = import.meta.glob('~/assets/icons/**/*.svg', { query: '?raw', import: 'default', eager: true })
const svg = computed(() => modules['/assets/icons/' + file + '.svg'])
</script>
